@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/* :root {
  --primary-color: #ff5604;
  --secondary-color: white;
} */

body {
  font-family: "Poppins";
}

.custom-google-authenticator {
  border: 0px solid red;
  margin: 0px -12px;
  padding: 30px;

}

.custom-inside-google-authenticator {
  border: 0px solid black;
  padding: 30px;
  text-align: center;
  margin-bottom: 10px;
}

.custom-inside-google-authenticator h2 {
  color: #BC9018;
  font-size: 40px;
}

.custom-inside-google-authenticator p {
  color: #BC9018;
}

.custom-store-section {
  border: 0px solid #D2B14A;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 6px 2px 16px 0 #d9bf7080, -6px -2px 16px 0 #a58c4180;
}

.custom-play-store {
  border: 0px solid gray;
  text-align: center;
  padding: 10px;
}

.custom-play-store-icon {
  font-size: 30px;
}

.custom-play-store button {
  border: 1px solid var(--primary-color);
  padding: 10px 40px;
  text-transform: capitalize;
  background-color: var(--primary-color);
  color: white;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
}

.custom-scan p {
  color: var(--primary-color);
}

@media (max-width:991px) {
  .custom-play-store button {
    width: 100%;
    padding: 10px 0px;
  }

  .c-sane-1 {
    width: 100% !important;
  }

  .custom-google-authenticator {
    padding: 10px 0px;
  }

  .custom-inside-google-authenticator {
    padding: 10px 0px;
  }

  .custom-store-section {
    padding: 10px 0px;
  }

  .custom-inside-google-authenticator h2 {
    font-size: 25px;
  }
}
@media (min-width:712px) and (max-width:991px){
  .c-sane-1 {
    width: 50% !important;
  }
  .custom-play-store button {
    width: 50%;
  }

}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<widthdrawcrypto>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-with-draw {
  border: 0px solid red;
  margin: 0px -12px;
  padding: 40px;
}

.coin-1 {
  color: var(--primary-color);
}

.custom-inside-with-draw h2 {
  font-size: 30px;
  color: var(--primary-color);
}

.custom-inside-with-draw a {
  font-size: 30px;
  color: var(--primary-color);
}

.custom-inside-with-draw button {
  border: 1px solid var(--secondary-color);
  padding: 10px 30px;
  text-transform: capitalize;
  background-color: var(--primary-color);
  color: white;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
}

.custom-withdraw-balance h5 {
  font-size: 14px;
}

.custom-withdraw-balance p {
  font-size: 15px;
}

.custom-withdraw-balance-bth button {
  border: 1px solid var(--primary-color);
  padding: 0px 0px;
  text-transform: capitalize;
  background-color: var(--primary-color);
  color: white;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
  min-width: 120px;
  min-height: 50px;
}

.custom-with-scroll {
  overflow: auto;
}

.custom-with-scroll th {
  font-size: 13px;
  font-weight: 500;
}

/* div.mm-dropdown {
    border: 1px solid #ddd;
    width: 100%;
    border-radius: 3px;
  }
  
  div.mm-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 0;
  }
  
  div.mm-dropdown ul li,
  div.mm-dropdown div.textfirst {
    padding: 0;
    color: #333;
    border-bottom: 1px solid #ddd;
    padding: 5px 15px;
  }
  
  div.mm-dropdown div.textfirst img.down {
    float: right;
    margin-top: 5px
    
  }
  
  div.mm-dropdown ul li:last-child {
    border-bottom: 0;

  }
  
  div.mm-dropdown ul li {
    display: none;
    padding-left: 25px;
  }
  
  div.mm-dropdown ul li.main {
    display: block;
  }
  
  div.mm-dropdown ul li img {
    width: 20px;
    height: 20px;
  }
  .select-size{
    width:5%;
  } */
@media (max-width:991px) {
  .custom-with-draw {
    padding: 10px 0px;
  }

  .custom-inside-with-draw button {
    padding: 10px 10px;
    font-size: 10px;
  }

  .custom-inside-with-draw h2 {
    font-size: 15px;
  }

  .custom-inside-with-draw {
    align-items: baseline;
  }
}

/* .f-dropdown {
  --max-scroll: 3;
  position: relative;
  z-index: 10;

  select {
    display: none;
  }

  &>span {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    position: relative;
    color: #bbb;
    border: 1px solid #ccc;
    background: #fff;
    transition: color .2s ease, border-color .2s ease;

    >span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 12px;
    }

    img {
      width: 30px;
      margin-right: 10px;
    }

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 8px;
      height: 2px;
      border-radius: 1px;
      top: 50%;
      right: 12px;
      background: #000;
      transition: all 0.3s ease;
    }

    &:before {
      margin-right: 4px;
      transform: scale(0.96, 0.8) rotate(50deg);
    }

    &:after {
      transform: scale(0.96, 0.8) rotate(-50deg);
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    max-height: calc(var(--max-scroll) * 46px);
    top: 40px;
    left: 0;
    z-index: 1;
    right: 0;
    background: #FFF;
    border: 1px solid #CCC;
    border-radius: 6px;
    overflow-x: hidden;
    overflow-y: auto;
    transform-origin: 0 0;
    transition: opacity 0.2s ease, visibility 0.2s ease,
      transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
    transform: translate(0, 5px);

    li {
      padding: 0;
      margin: 0;

      a {
        cursor: pointer;
        display: block;
        padding: 8px 12px;
        color: #000;
        text-decoration: none;
        outline: none;
        position: relative;
        transition: all .2s ease;
        display: flex;
        align-items: center;

        img {
          width: 30px;
          margin-right: 10px;
        }

        &:hover {
          color: #5C6BC0;
        }
      }

      &.active {
        a {
          color: #FFF;
          background: #303F9F;

          &:before,
          &:after {
            --scale: 0.6;
            content: "";
            display: block;
            width: 10px;
            height: 2px;
            position: absolute;
            right: 12px;
            top: 50%;
            opacity: 0;
            background: #FFF;
            transition: all 0.2s ease;
          }

          &:before {
            transform: rotate(45deg) scale(var(--scale));
          }

          &:after {
            transform: rotate(-45deg) scale(var(--scale));
          }

          &:hover {

            &:before,
            &:after {
              --scale: 0.9;
              opacity: 1;
            }
          }
        }
      }

      &:first-child {
        a {
          border-radius: 6px 6px 0 0;
        }
      }

      &:last-child {
        a {
          border-radius: 0 0 6px 6px;
        }
      }
    }
  }

  &.disabled {
    opacity: 0.7;

    >span {
      cursor: not-allowed;
    }
  }

  &.filled {
    &>span {
      color: #000;
    }
  }

  &.open {
    z-index: 15;

    &>span {
      border-color: #AAA;

      &:before,
      &:after {
        background: #000;
      }

      &:before {
        transform: scale(0.96, 0.8) rotate(-50deg);
      }

      &:after {
        transform: scale(0.96, 0.8) rotate(50deg);
      }
    }

    ul {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 12px);
      transition: opacity 0.3s ease, visibility 0.3s ease,
        transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
    }
  }
} */

/* --------------------------- */

/* .f-group {
  max-width: 250px;
  margin: 0 auto;
  text-align: left;

  select {
    width: 100%;
  }
}

.f-control {
  font-size: 14px;
  line-height: normal;
  color: #000;
  display: inline-block;
  background-color: #ffffff;
  border: #ccc 1px solid;
  border-radius: 6px;
  padding: 8px 12px;
  outline: none;
  width: 100%;
}

label {
  width: 100%;
  display: block;

  margin-bottom: 10px;
  text-align: start;
} */

/* body {
    font-family: Arial, sans-serif;
    font-size: 14px;
    padding: 80px 10px;
    background: #e3f3ff;
  } */

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-deposite-wallet>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-deposite-walldropdownet {
  border: 0px solid red;
  margin: 0px -12px;
  padding: 40px;
}

.custom-inside-deposite h2 {
  font-size: 30px;
  color: var(--primary-color);
  border: 0px solid red;
}

.custom-inside-deposite a {
  font-size: 30px;
  color: var(--primary-color);
  border: 0px solid red;
}

.custom-wallet-icon {
  border: 0px solid red;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 90px;
  font-size: 40px;
  color: #AAA;
}

.custom-wallet-box {
  border: 0px solid blue;
  padding: 20px 50px;
  position: relative;
  background: antiquewhite;
  z-index: 2;
  margin-top: 20px;
  border-radius: 15px;
}

.custom-box-line {
  border: 0px solid red;

}

.custom-box-line h3 span {
  border: 0px solid red;
  width: 40px;
  display: block;
  border-radius: 47px;
  text-align: center;
  font-size: 15px;
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
}

.custom-box-line h2 {
  border: 0px solid red;
  font-size: 15px;
}

.custom-box-line p {
  border: 0px solid red;
  font-size: 12px;

}

.custom-wallet-line-border {
  border: 1px solid var(--primary-color);
  position: relative;
  top: 21px;
  z-index: -1;
  width: 78%;

}

.out-line {
  outline: none;
}

.custom-scane-data {
  border: 0px solid red;

}

.custom-scane-data img {
  width: 30%;
  height: 50%;

}

.custom-deposite-address {
  border: 0px solid red;
  text-align: right;
}

.custom-deposite-address span {
  color: var(--primary-color);
}

.custom-recent-deposit {
  border: 0px solid red;
}

.custom-recent-deposit h5 {
  color: var(--primary-color);
}

.custom-wallet-search {
  text-align: center;
}

.custom-wallet-search :nth-child(1) {
  color: #bbb;
  font-size: 60px;
}

.custom-wallet-search h2 {
  font-size: 15px;
}

.deposite-spinner {
  margin: 0px auto;
  color: #bc9018;
  width: 4rem;
  height: 4rem;
}

@media(max-width:991px) {
  .custom-deposite-wallet {
    padding: 10px 0px;
  }

  .custom-inside-deposite h2 {
    font-size: 25px;
  }

  .custom-wallet-line-border {
    border: 0px;
  }

  .custom-wallet-box {
    padding: 10px 15px;
  }

  .custom-wallet-icon {
    position: unset;
    margin-bottom: 10px;
  }
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.input-select {
  box-shadow: none;
}

.input-select:focus {
  border-color: red !important;
}

.address-1-btn {
  border: 1px solid var(--primary-color);
  padding: 10px 30px;
  text-transform: capitalize;
  background-color: var(--primary-color);
  color: white;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
}
.custom-deposite-loader-1{
  min-height: 350px;
}
.opt-btn{
  border: 1px solid var(--primary-color);
  padding: 0px 0px;
  text-transform: capitalize;
  background-color: var(--primary-color);
  color: white;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
  min-width: 120px;
    min-height: 50px;
    display: inline-block;
}