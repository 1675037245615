@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

body {
  background-color: #fff;
  color: #000;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  background: #D0B000;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* ::-webkit-scrollbar-track {
  width: 5px;
  height: 5px;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D0B000;
}

.fs-60 {
  font-size: 60px;
}

.fs-25 {
  font-size: 25px;
}

.fs-20 {
  font-size: 20px;
}

.fs-15 {
  font-size: 15px;
}

.fs-18 {
  font-size: 18px;
}



.fcaps {
  text-transform: capitalize;
}

.userInput span {
  display: none;
}
.asset-ampoule-1{
  color: var(--primary-color);
  text-decoration: underline;
}
/* / ============ DESKTOP-VIEW ============ / */

@media all and (min-width: 992px) {
  .heading-1-css {
    font-size: 55px;
    font-weight: 900 !important;
    font-family: "Poppins", sans-serif;
  }

  .main-btn-css-1 {
    color: black;
    font-size: 19px;
    background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
    border-radius: 0.6em;
    width: 300px;
  }

  .main-btn-css-1:active {
    color: black;
    font-size: 19px;
    background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
    border-radius: 0.6em;
    width: 300px;
    border: none;
  }


  .main-btn-css-1:hover {
    color: black;
    font-size: 19px;
    background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
    border-radius: 0.6em;
    width: 300px;
    box-shadow: none;
    outline: none;
  }

  .register-bg-styling-css {
    background-image: url("../image/Ellipse-23.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 25%;
  }

  .register-card-bg-styling {
    background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
    border-radius: 1em;
  }

  .usermask-image-css {
    width: 80px;
    height: 80px;
  }

  .login-inputs-css .form-control {
    background-color: transparent;
    border: 1px solid #ffff;
    padding: 0.8em;
    margin-bottom: 2em;
    justify-content: center;
    box-shadow: none;
    outline: none;
    color: #ffff;
  }

  .login-inputs-css .form-control:hover {
    background-color: transparent;
    border: 1px solid #ffff;
    padding: 0.8em;
    margin-bottom: 2em;
    justify-content: center;
    box-shadow: none;
    outline: none;
    color: #ffff;
  }

  .login-inputs-css .form-control::placeholder {
    color: #f3f3f3c4;
  }

  .login-inputs-css .form-check-input {
    background-color: transparent;
    border: 1px solid #ffff;
    box-shadow: none;
    outline: none;
  }

  .login-inputs-css .form-check-input:hover {
    background-color: transparent;
    border: 1px solid #ffff;
    box-shadow: none;
    outline: none;
  }

  .login-bg-styling-css {
    background-image: url("../image/Ellipse-22.png");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 20%;
  }

  .userInput {
    display: flex;
    justify-content: center;
  }

  .userInput input {
    margin: 10px;
    height: 45px;
    width: 35px !important;
    border-radius: 5px;
    text-align: center;
    font-size: 1.2rem;
    background: transparent;
    border: 1px solid #ffff;
    color: #ffff !important;
    outline: none !important;
  }

  .userInput input:hover {
    margin: 10px;
    outline: none;
    border-radius: 5px;
    text-align: center;
    box-shadow: none;
    font-size: 1.2rem;
    background: transparent;
    border: 1px solid #ffff;
    color: #ffff !important;
  }

  .authenticator-card-bg-styling {
    background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
    border-radius: 1em;
    height: 450px;
  }

  .card-title-styling-css {
    font-size: 20px;
    color: #BC9018;
    font-weight: 500;
  }

  .assets-card-bg-styling-css {
    background-color: #eaeaeac5;
    border: 2px solid #ffffffd1;
    border-radius: 1.5em;
    box-shadow: 0px 0px 8px gray;
  }

  .assets-card-inner-text-css {
    font-size: 11px;
    font-weight: 500;
    text-align: start;
  }

  .buy-card-button-styling-css {
    background-color: #943101;
    color: #ffff;
    width: 150px;
    border-radius: 0.4em;
  }
}

/* / ============ MOBILE-VIEW ============ / */

@media (max-width: 991px) {
  .heading-1-css {
    font-size: 65px;
    font-weight: 900 !important;
    font-family: "Poppins", sans-serif;
  }

  .main-btn-css-1 {
    color: #ffff;
    font-size: 19px;
    background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
    border-radius: 0.6em;
    min-width: 200px;
  }

  .main-btn-css-1:hover {
    color: #ffff;
    font-size: 19px;
    background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
    border-radius: 0.6em;
    width: 200px;
    box-shadow: none;
    outline: none;
  }

  .register-bg-styling-css {
    background-image: url("../image/Ellipse-23.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 50%;
  }

  .register-card-bg-styling {
    background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
    border-radius: 1em;
  }

  .usermask-image-css {
    width: 50px;
    height: 50px;
  }

  .login-inputs-css .form-control {
    background-color: transparent;
    border: 1px solid #ffff;
    padding: 0.8em;
    margin-bottom: 2em;
    justify-content: center;
    box-shadow: none;
    outline: none;
    color: #ffff;
  }

  .login-inputs-css .form-control:hover {
    background-color: transparent;
    border: 1px solid #ffff;
    padding: 0.8em;
    margin-bottom: 2em;
    justify-content: center;
    box-shadow: none;
    outline: none;
    color: #ffff;
  }

  .login-inputs-css .form-control::placeholder {
    color: #f3f3f3c4;
  }

  .login-inputs-css .form-check-input {
    background-color: transparent;
    border: 1px solid #ffff;
    box-shadow: none;
    outline: none;
  }

  .login-inputs-css .form-check-input:hover {
    background-color: transparent;
    border: 1px solid #ffff;
    box-shadow: none;
    outline: none;
  }

  .login-bg-styling-css {
    background-image: url("../image/Ellipse-22.png");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 20%;
  }

  .userInput {
    display: flex;
    justify-content: center;
  }

  .userInput input {
    margin: 4px;
    height: 40px;
    width: 30px !important;
    border-radius: 5px;
    text-align: center;
    font-size: 1.2rem;
    background: transparent;
    border: 1px solid #ffff;
    color: #ffff !important;
    outline: none;
  }

  .userInput input:hover {
    margin: 4px;
    outline: none;
    border-radius: 5px;
    text-align: center;
    box-shadow: none;
    font-size: 1.2rem;
    background: transparent;
    border: 1px solid #ffff;
    color: #ffff !important;
  }

  .authenticator-card-bg-styling {
    /* background-color: #df4c03; */
    background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
    border-radius: 1em;
    height: 450px;
  }

  .card-title-styling-css {
    font-size: 20px;
    color: #BC9018;
    font-weight: 500;
  }

  .assets-card-bg-styling-css {
    background-color: #eaeaeac5;
    border: 2px solid #ffffffd1;
    border-radius: 1.5em;
  }

  .assets-card-inner-text-css {
    font-size: 12px;
    font-weight: 500;
    text-align: start;
  }

  .buy-card-button-styling-css {
    background-color: #943101;
    color: #ffff;
    width: 150px;
    border-radius: 0.4em;
  }
}

.register-btn-1 {
  border: 1px solid var(--primary-color);
  padding: 10px 40px;
  text-transform: capitalize;
  background-color: var(--primary-color);
  color: white;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
}

.invalid-email {
  color: red;
}

.invalid-login {
  color: black;


}

/* .Register{
  color: white;
  outline: none !important;
  border: none !important;
  background: transparent;
} */
.check-box {
  margin-top: 4px;
}

.custom-aut-btn {
  border: 1px solid var(--primary-color);
  width: 45px;
  height: 45px;
  text-transform: capitalize;
  background-color: var(--primary-color);
  color: white;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
}

.with-input {
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 1px solid white !important;
  color: white !important;
  border-right: none !important;

}

.with-input:focus {
  background-color: #4B4B4B;
  color: white !important;
  border-color: white !important;
}

.with-inputs:focus {
  border-color: white !important;
}

.eye-icon {
  height: 52px;
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 1px solid white !important;
  color: white !important;
  border-left: none !important;
}

.reset-eye {
  height: 38px;
}

/* copy */

.copy {
  color: red;
}

.page_404 {
  padding: 40px 0;
  background: #fff;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {

  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}


.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}

.custom-line-1 {
  border-top: 1px solid #BC9018;
}

.Redeem-btn {
  border: 1px solid var(--primary-color);
  text-transform: capitalize;
  background-color: var(--primary-color);
  color: white;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
  padding: 10px 25px;
}

.fade-disabled {
  opacity: 0.5 !important;
  /* Adjust the opacity value as needed */
  pointer-events: none;
  /* Disable pointer events to make the button unclickable */
}

.text-kyc {
  color: #D0B000;
  font-size: 25px;
}
.option-new-text{
 font-size: 14px;
 position: relative;
 top: 2px;
 left: 2px;
}
@media (min-width:280px) and (max-width:319px){
  .assets-card-inner-text-css {
    font-size: 10px;
    font-weight: 500;
    text-align: start;
  }
}
.privacy-nav-1 .custom-insidenav {
  background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%)) !important;
}
.privacy-nav-1 .custom-insidenav .custom-nav-hover:hover::after {
  border-bottom: 2px solid white;
  width: 100%;
  left: 0;
}

.privacy-nav-1 .custom-insidenav .custom-nav-hover::after {
  bottom: 0;
  content: "";
  width: 0%;
  position: absolute;
  background: #fff;
  transition: width .5s, left .5s;
}
.terms-nav-1 .custom-insidenav {
  background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%)) !important;
}
.terms-nav-1 .custom-insidenav .custom-nav-hover:hover::after {
  border-bottom: 2px solid white;
  width: 100%;
  left: 0;
}

.terms-nav-1 .custom-insidenav .custom-nav-hover::after {
  bottom: 0;
  content: "";
  width: 0%;
  position: absolute;
  background: #fff;
  transition: width .5s, left .5s;
}