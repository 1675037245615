@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

* {
  margin: 0px;
  padding: 0px;
}

:root {
  --primary-color: #BC9018;
  --secondary-color: white;
}

body {
  font-family: "Poppins";
}

/* .custom-container{
  width:1187px;
  margin: 0px auto;
} */
img {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
}

.navbar-toggler {
  border: 1px solid #000;
  box-shadow: none !important;
}

.custom-nav-hover {
  padding: 10px 0px !important;
  border-bottom: 2px solid transparent;
}
.custom-nav-hover:hover::after {
  border-bottom: 2px solid #D0B000;
  width: 100%;
  left: 0;
}
.custom-nav-hover::after {
  bottom: 0;
  content: "";
  width: 0%;
  position: absolute;
  background: #fff;
  transition: width .5s, left .5s;
}
/* .custom-nav-hover{
position: relative;
}
.custom-nav-hover::before
{
  content:"";
  position: absolute;
  width:0px;
  height:1px;
  background-color: red;
  bottom:0;
}
.custom-nav-hover:hover::before{
  width:100%;
} */
.custom-insidenav .navbar .nav-link,
.navbar .nav-link:hover,
.navbar .nav-link:active {
  background-color: none !important;
}
.custom-nav-last-section {

  margin-left: 30px;
  font-size: 25px;
  color: white;

}
@media (max-width:991px) {
  .custom-nav-last-section {
    margin: 0px;
  }

  .custom-container {
    width: 100%;
    margin: 0px auto;
  }
}
.custom-user-drop {
  background-color: white;
  color: white !important;
  padding: 0px;
  min-width: 8rem !important;
  right: 0 !important;
  left: auto !important;
}
.custom-user-drops .dropdown-item:hover {
  background-color: var(--primary-color);
  color: white;

}
.dropdown-toggle::after {
  margin-bottom: -3px;
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-lorem>>>>>>>>>>>>>>>>>>>>>>> */

.custom-home-banner-2 .swiper-pagination-bullet {
  background-color: var(--secondary-color) !important;
  opacity: 2;
}
.custom-home-banner-2 .swiper-pagination-bullet-active {
  background-color: #ff2d55 !important;
}
.custom-home-banner-2 .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.custom-home-banner-2 .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 30px !important;
  height: 3px !important;
  border-radius: 10px !important;
  transition: 0.5s;
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-dotedesign>>>>>>>>>>>>>> */
.custom-dotedesign img {
  opacity: 0.7;
}
.custom-dotedesign,
.custom-home-banner-3,
.custom-home-banner-2,
.custom-home-banner-body {
  border: 0px solid red;
  margin: 0px -12px;
}
/* .row{
  margin:0px;
} */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<,end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-dummy>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-dummy {
  border: 0px solid red;
  margin: 0px -12px;
  padding: 50px 50px 0px 50px;
}
.custom-dummy-left {
  border: 0px solid blue;
}
.custom-dummy-left h3 {
  font-size: 25px;
  border-left: 2px solid #D0B000;
  padding-left: 5px;
  text-transform: capitalize;
}
.custom-dummy-right {
  border: 0px solid blue;
}
.custom-lorem-ispum {
  /* border:1px solid red; */
  margin-bottom: 10px;
  padding: 10px 0px;
}
.custom-lorem-ispum img {
  width: 20%;
  box-shadow: 0px 2px 5px gray;
  border-radius: 50%;
  padding: 5px;
}
.custom-lorem-ispum h2 {
  font-size: 18px;
  margin-top: 15px;
  text-transform: capitalize;
}
.custom-lorem-ispum p {
  font-size: 12px;
  margin-top: 15px;
}
.customhand-phone {
  height: 650px;
  object-fit: contain;
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-typesetting>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-typesetting {
  /* border:1px solid red; */
  margin: 0px -12px;
  padding: 50px;
}

.ecosystem {
  /* border: 1px solid red; */
  text-align: center;
  margin-top: 40px;
}

.ecosystem h3 {
  font-size: 40px;
  color: #D0B000;
  font-weight: bold;
  font-family: "Roboto";
}

/* .setting{
  height: 100%;
} */
/* .setting img{
 object-fit: cover;
} */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-brand>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-brand {
  /* border:1px solid red; */
  margin: 0px -12px;
  padding: 50px;
}

.custom-insideboxbrand {
  border: 0px solid red;
}

.custom-brandborder {
  border-right: 1px solid #9a9b9b;
  /* border-bottom: 1px solid #9a9b9b; */
  padding: 40px;
}

.custom-brandborder img {
  width: 100%;
  object-fit: contain;
}

.custom-redditborder {

  padding: 40px;
}

.custom-redditborder img {
  width: 100%;
}

.custom-brandborders {
  border-right: 1px solid #9a9b9b;
}

.token-img-paper img {
  width: 30%;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-bussiness>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-bussiness {
  /* border:1px solid red; */
  margin: 0px -12px;
  background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
  padding: 0px 12px 0px 0px;
}

.custom-bussiness-left {
  /* border: 1px solid blue; */
  height: 100%;
}

.custom-bussiness-left img {
  width: 60%;
  height: 100%;
}

.custom-bussiness-center h3 {
  /* border: 1px solid red; */
  color: var(--secondary-color);
  font-size: 22px;
}

.bussiness-btn button {
  text-transform: uppercase;
  border: 0px;
  border-radius: 10px;
  padding: 15px 9px;
}

@media(min-width: 280px) and (max-width: 991px) {
  .custom-bussiness-center h3 {
    font-size: 15px;
    margin: 20px 0px;
  }

  .bussiness-btn button {
    padding: 5px 5px;
  }
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-fotter>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-fotter {
  border: 0px solid red;
  margin: 0px -12px;
  padding: 50px;
}

.custom-fotter-left {
  border: 0px solid blue;
}

.custom-fotter-left a {
  text-decoration: none;
}

.custom-footer-list {
  border: 0px;
  text-transform: capitalize;
  font-weight: 500;
}

.custom-fotter-right {
  border: 0px solid red;
}

.custom-fotter-right h2 {
  font-size: 60px;
}

.custom-fotter-right a {
  text-decoration: none;
  color: black;
}

.custom-fotter-right p {
  font-size: 15px;
}

.custom-fotter-right img {
  width: 60%;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<responsive>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
@media (max-width: 991px) {
  .custom-filter {
    left: 0px !important;
    top: 0px !important;
  }

  .custom-body {
    padding: 100px 20px;
  }

  .custom-lorem,
  .custom-dummy,
  .custom-typesetting,
  .custom-brand,
  .custom-bussiness,
  .custom-fotter {
    padding: 0px 0px;
  }

  .custom-bussiness-left img {
    display: none;
  }

  .list-group-horizontal {
    flex-direction: column;
  }

  .custom-maskimg img {
    width: 15%;
  }

  .custom-tiger-user {
    left: 23px !important;
    top: 80px !important;
  }

  .custom-listnav li {
    margin: 0px;
  }

  .custom-lorem-ispum p {
    font-size: 15px;
  }

  .custom-lorem-ispum img {
    width: 15%;
  }

  .custom-footer-list {
    padding: 10px 0px;
  }

  .custom-brandborder,
  .custom-brandborders,
  .custom-redditborder {
    border: 0px;
    text-align: center;
  }

  .custom-brandborder img {
    width: 50%;
    object-fit: contain;
  }

  .custom-user-mo {
    text-align: left !important;
  }

  .custom-maskimg {
    margin: inherit !important;
  }

  .custom-isotopy {
    padding: 160px 0px !important;
  }

  .row {
    margin: 0px;
  }

  .token-img-paper img {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 420px) {
  .custom-tiger-user {
    left: 8px !important;
    top: 45px !important;
  }
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-isotope>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-isotopy {
  /* border:1px solid red; */
  padding: 100px 50px;
  margin: 0px -12px;
}

.custom-isotopy p {
  text-align: justify !important;
}

.custom-isotopy h2 {
  color: var(--primary-color);
  text-transform: capitalize;
}

.custom-isotoprnav .custom-insidenav ul li:nth-child(3) a {
  color: white !important;
}

.custom-isotoprnav .custom-insidenav ul li:nth-child(2) a {
  color: black !important;
}

.custom-isotoprnav .custom-insidenav ul li:nth-child(1) a {
  color: black !important;
}

.custom-isotoprnav .custom-insidenav {
  /* background: none !important; */
  background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%)) !important;
  background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%)) !important;
}

.custom-isotoprnav .navbar-new-link-text-2 {
  color: black !important;
  width: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-isotoprnav .custom-insidenav .custom-nav-hover:hover::after {
  border-bottom: 2px solid white;
  width: 100%;
  left: 0;
}

.custom-isotoprnav .custom-insidenav .custom-nav-hover::after {
  bottom: 0;
  content: "";
  width: 0%;
  position: absolute;
  background: #fff;
  transition: width .5s, left .5s;
}

video#video-background {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: -1;
}



.custom-isotope-box-1 {
  border: 0px solid #D0B000;
  text-align: center;
  padding: 20px;
  margin: 10px 0px;
}

.custom-isotope-box-1 img {
  border: 0px solid #D0B000;
  width: 15%;
}

.custom-isotope-box-1 h2 {
  color: black;
  font-size: 20px;
  font-weight: 500;
}

.custom-isotope-box-1 p {
  text-align: center !important;
}

.treasure-btn-2 {
  border: 1px solid var(--primary-color);
  padding: 0px 0px;
  text-transform: capitalize;
  background-color: var(--secondary-color);
  color: black;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 10px;
  min-width: 120px;
  min-height: 45px;
}

.treasure-btn-2:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.treasure-btn-2.active {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Harish>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

/* ============ DESKTOP-VIEW ============  */

@media all and (min-width: 992px) {

  .custom-treasure1 {
    position: absolute;
    bottom: 10%;
    left: 6%;
  }

  .custom-loremleft h2 {
    font-size: 36px;
    text-transform: uppercase;
    font-family: "Roboto";
    color: #fff6f2;
    font-weight: bolder !important;
  }

  .custom-loremleft p {
    color: white;
    font-size: 20px;
  }

  .custom-home-banner-2-inner {
    background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
    padding: 10px 40px;
    border-radius: 35px;
  }

  .custom-home-banner-2 {
    padding: 50px;
  }

  .treasure-btn-1 {
    border: 1px solid var(--primary-color);
    padding: 10px 40px;
    text-transform: capitalize;
    background-color: var(--secondary-color);
    color: black;
    border-radius: 10px;
    font-size: 16px;
    text-transform: uppercase;
  }

  .treasure-btn-1:hover {
    background-color: var(--primary-color);
    color: var(--secondary-color);
  }

  .treasure-btn-1.active {
    background-color: var(--primary-color);
    color: var(--secondary-color);
  }

  .custom-filter {
    width: 200px;
    height: 150px;
    background-color: #ff9561;
    filter: blur(80px);
    position: absolute;
    top: -80px;
    left: 60%;
    z-index: 99;
  }

  .banner-text-2 {
    font-size: 1.3rem;
    font-weight: 500;
    opacity: 0.9;
  }

  .banner-text-1 {
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: 600;
    font-family: "Roboto";
    color: var(--primary-color);
  }

  .custom-home-banner-body {
    padding: 20px 50px;
    /* background-image: url("./assets/image/Group\ 97.png"); */
    background-position: right;
    background-repeat: no-repeat;
    background-size: 50% 100%;
  }

  .custom-treasure {
    margin-top: 30px;
  }

  .custom-maskimg {
    width: 45px;
    height: auto;
    margin: auto !important;
  }

  .navbar-new-link-text-1 {
    color: #fff !important;
    font-size: 18px;
    position: relative;
    left: -30%;
  }

  .navbar-new-link-text-2 {
    color: white !important;
    font-size: 18px;
  }

  .custom-insidenav {
    background: rgba(0, 0, 0, .555);
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    padding: 4px 0px !important;
  }

  .custom-token-box {
    margin-left: 50px;
  }
}


/* ============ MOBILE-VIEW ============ */

@media (min-width:280px) and (max-width:319px) {
  .treasure-btn-1 {
    padding: 10px 15px !important;
  }

  .custom-home-banner-2 .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
  .custom-home-banner-2 .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    width: 20px !important;
  }
}

@media (max-width: 991px) {
  .custom-loremleft h2 {
    font-size: 18px;
    text-transform: uppercase;
    color: #fff6f2;
    font-weight: 500 !important;
  }

  .custom-loremleft p {
    color: black;
    font-size: 15px;
  }

  .min-vh-100 {
    min-height: 50vh !important;
  }

  video#video-background {
    position: absolute;
    width: 100%;
    height: 50vh;
    left: 0;
    top: 0;
    object-fit: cover;
    z-index: -1;
  }

  /* .custom-treasure1 {
    position: absolute;
    bottom: 0%;
    left: 6%;
  } */

  .customhand-phone {
    height: 250px;
    object-fit: contain;
  }

  .custom-home-banner-2-inner {
    background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
    padding: 20px 0px;
    border-radius: 35px;
  }

  .custom-home-banner-2-inner .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .custom-home-banner-2 {
    padding: 10px;
  }

  .treasure-btn-1 {
    border: 1px solid var(--primary-color);
    padding: 10px 20px;
    text-transform: capitalize;
    background-color: var(--secondary-color);
    color: black;
    border-radius: 10px;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .treasure-btn-1:hover {
    background-color: var(--primary-color);
    color: var(--secondary-color);
  }

  .treasure-btn-1.active {
    background-color: var(--primary-color);
    color: var(--secondary-color);
  }

  .custom-filter {
    width: 200px;
    height: 150px;
    background-color: #ff9561;
    filter: blur(80px);
    position: absolute;
    top: -80px;
    left: 60%;
    z-index: 99;
  }

  .banner-text-2 {
    font-size: 1rem;
    font-weight: 500;
    opacity: 0.9;
  }

  .banner-text-1 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--primary-color);
  }

  .custom-home-banner-body {
    padding: 5px 0px 40px 0px;
    /* background-image: url("./assets/image/Group\ 97.png"); */
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 50% 50%;
  }

  .custom-maskimg {
    width: 40px;
    height: auto;
  }

  .custom-insidenav {
    background: #fff !important;
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    padding: 14px 0px !important;
  }

  .navbar-new-link-text-1 {
    color: #000 !important;
    font-size: 14px;
  }

  .navbar-new-link-text-2 {
    color: #000 !important;
    font-size: 14px;
    margin-right: auto !important;
  }

  .custom-dummy-left h3 {
    font-size: 20px;
  }

  .ecosystem h3 {
    font-size: 30px;
  }
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<custom-bulk-order>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-bulk-order {
  padding: 100px 40px;
  margin: 0px -12px;
}

.custom-edit-profile-pop-up {
  padding: 20px 40px;
  margin: 0px -12px;
}

@media (max-width:991px) {
  .custom-edit-profile-pop-up {
    padding: 0px;
  }

  .variable-width {
    width: 100% !important;
  }
}

.custom-tobulk-nav .custom-insidenav ul li:nth-child(1) a {
  color: white !important;
}

.custom-tobulk-nav .custom-insidenav .dash-1 ul li:nth-child(1) a {
  color: black !important;
}

.custom-tobulk-nav .custom-insidenav ul li:nth-child(2) a {
  color: black !important;
}

.custom-tobulk-nav .custom-insidenav ul li:nth-child(3) a {
  color: black !important;
}

.custom-tobulk-nav .custom-insidenav {
  /* background: none !important; */
  background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%)) !important;
}

.custom-tobulk-nav .custom-insidenav .navbar-toggler {
  margin: 0px 10px 0px 0px;
}

.custom-tobulk-nav .navbar-new-link-text-2 {
  color: black !important;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-tobulk-nav .custom-insidenav .custom-nav-hover:hover::after {
  border-bottom: 2px solid white;
  width: 100%;
  left: 0;
}

.custom-tobulk-nav .custom-insidenav .custom-nav-hover::after {
  bottom: 0;
  content: "";
  width: 0%;
  position: absolute;
  background: #fff;
  transition: width .5s, left .5s;
}


@media(min-width:280px) and (max-width:991px) {
  .custom-tobulk-nav .custom-insidenav {
    background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%)) !important;
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    padding: 14px 0px !important;
  }

  .custom-isotoprnav .custom-insidenav {
    background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%)) !important;
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    padding: 14px 0px !important;
  }

}

.navbar-toggler {
  margin: 0px 10px 0px 0px;
}

.custom-inside-bulk-order {
  border: 1px solid #D2B14A;
  text-align: center;
  padding: 20px 0px;
  background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
  color: white;
  border-radius: 15px 15px 0px 0px;
}

.custom-contact-form {
  border: 1px solid #D2B14A;
  padding: 50px;
  width: 100%;
}

/* .custom-type-here {
  height: 100px;
} */


.custom-contact-color {
  color: var(--primary-color);
  text-align: end;
}

.custom-city-form {
  border: 1px solid #dee2e6;
  background-color: white;
  border-radius: 5px;
  outline: none;
}

/* .custom-value-color{
  color: rgba(148, 148, 148, 0.34) !important;
  width:100%;
} */
.custom-icon-form {
  border: 0px solid red;
}

.custom-calender-form {
  border: 0px solid red;
}

.custom-calender-form h3 {
  color: var(--primary-color);
}

.custom-date-picker input[type="text"],
input[type="password"],
textarea {
  border: 1px solid rgba(148, 148, 148, 0.34);
}

/* .custom-date-picker input[type="text"]:focus, input[type="password"]:focus, textarea:focus{
  border:1px solid gray !important;
} */
@media (max-width:991px) {
  .custom-contact-form {
    padding: 10px 0px;
  }

  .custom-contact-color {
    text-align: start;
  }

  .custom-value-color {
    margin-bottom: 10px;

  }

  .custom-icon-form {
    margin-bottom: 10px;
  }

  .custom-bulk-order {
    padding: 100px 15px;
  }

  .custom-bulk-box {
    padding: 0px;
  }

  .custom-calender-btn {
    text-align: start !important;
  }
}

@media (min-width:700px) and (max-width:991px) {
  .custom-contact-color {
    text-align: end;
    /* white-space: nowrap;
    margin-right: 25px; */
  }
}

.react-datepicker__input-container input {
  font-size: 14px;
}

.react-datepicker__calendar-icon {
  fill: var(--primary-color);
}

.custom-date-calender {
  width: 100%;
  border-radius: 5px;
}

.custom-calender-btn button {
  border: 1px solid var(--primary-color);
  padding: 0px 0px;
  text-transform: capitalize;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
  min-width: 120px;
  min-height: 50px;
}

.custom-calender-btn button:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #dee2e6 !important;
}

.custom-frame {
  width: 100%;
  height: 300px;
}

.custom-bulk-order .react-tel-input .form-control {
  width: 100%;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-buy>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-buy {
  margin: 0px -12px;
  padding: 100px 0px;
}

.custom-inside-buy {
  padding: 20px;
  text-align: center;
}

.custom-buy-navbar .custom-insidenav ul li:nth-child(2) a {
  color: white !important;
}

.custom-buy-navbar .custom-insidenav ul li:nth-child(3) a {
  color: black !important;
}

.custom-buy-navbar .custom-insidenav ul li:nth-child(1) a {
  color: black !important;
}

.custom-buy-navbar .custom-insidenav {
  /* background: none !important; */
  background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%)) !important;
}

.custom-buy-navbar .navbar-new-link-text-2 {
  color: black !important;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-buy-navbar .custom-insidenav .custom-nav-hover:hover::after {
  border-bottom: 2px solid white;
  width: 100%;
  left: 0;
}

.custom-buy-navbar .custom-insidenav .custom-nav-hover::after {
  bottom: 0;
  content: "";
  width: 0%;
  position: absolute;
  background: #fff;
  transition: width .5s, left .5s;
}

.custom-buybottle {
  height: 90%;
}

@media (max-width:991px) {
  .custom-buy {
    padding: 100px 0px;
  }

  .custom-buybottle {
    width: 15%;
    height: 100%;
  }

  .custom-inside-buy {
    padding: 0px;
    text-align: center;
  }

  .custom-buy-navbar .custom-insidenav {
    background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%)) !important;
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    padding: 14px 0px !important;
  }
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-thankyou>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-thankyou {
  border: 0px solid red;
  margin: 0px -12px;
  padding: 100px;
  color: white;

}

.custom-inside-thankyou {
  border: 1px solid #ffffff21;
  text-align: center;
  padding: 20px;
  background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
}

.custom-thankyoubtn {
  border: 1px solid var(--primary-color);
  padding: 10px 40px;
  text-transform: capitalize;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
}

.custom-thankyoubtn:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.thankyou-icon {
  font-size: 45px;
  /* color:var(--primary-color); */
  /* border: 1px solid white; */
  padding: 20px;
  padding: 5px;
  border-radius: 50px;
}

@media (max-width:991px) {
  .custom-thankyou {
    padding: 100px 0px;
  }
}


.custom-buy-navbarss .custom-insidenav ul li:nth-child(3) a {
  color: var(--primary-color) !important;
}

.custom-buy-navbarss-new .custom-insidenav ul li:nth-child(3) a {
  color: var(--primary-color) !important;
}

.custom-buy-navbarss-new .custom-insidenav ul li:nth-child(1) a {
  color: #000 !important;
}

.custom-buy-navbarss-new .display-hide {
  display: none;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<,end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

/* Validation */
.invalid-email {
  color: red;
}

.custom-buy-navbarss-new .display-hide {
  display: none;
}

.custom-buy-navbar .custom-buy-navbarss-new .custom-insidenav {
  display: none !important;
}

.custom-fraction-modal .modal {
  --bs-modal-width: 1000px;
}

.display-hide .navbar {
  display: none !important;
}

.display-hide .custom-bulk-order {
  padding: 20px;
}

/* <<<<<<<<<<<<<<<<<<<<<<custom-view>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-view {
  border: 0px solid red;
  margin: 0px -12px;
  padding: 10px 20px;
}

.custom-google-swiper .swiper-pagination {
  color: var(--primary-color);
}

.custom-google-swiper .mySwiper {
  margin-bottom: 0px !important;
  height: 100%;
}

.custom-google-swiper .swiper-button-next {
  position: absolute;
  /* top: 90% !important;
    right: 46% !important; */
  border: 1px solid var(--primary-color);
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  color: var(--primary-color);
  opacity: 1;
  padding: 20px;
}

.custom-google-swiper .swiper-button-prev {
  position: absolute;
  /* top: 90% !important;
  right: 46% !important; */
  border: 1px solid var(--primary-color);
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  color: var(--primary-color);
  opacity: 1;
  padding: 20px;
}

.custom-google-swiper .swiper-button-prev:after {
  font-size: 20px !important;
}

.custom-google-swiper .swiper-button-next:after {
  font-size: 20px !important;
}

.view-img-slide {
  width: 50%;
}

@media(max-width:991px) {
  .view-img-slide {
    width: 100%;
  }

  .custom-google-swiper .swiper-button-next,
  .custom-google-swiper .swiper-button-prev {
    display: none;
  }

  .custom-view {
    padding: 0px;
  }
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-balance {
  color: var(--primary-color);
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Loader in >>>>>>>>>>>>>>>>>>>>>>>>>>> */

.spinner {
  width: 8em;
  height: 8em;
  border-radius: 100%;
  margin: 100px auto;
  border: 4px solid rgb(188, 144, 24);
  border-left-color: #fff;
  -webkit-animation: load 1.1s infinite linear;
  /* Chrome, Safari, Opera */
  animation: load 1.1s infinite linear;
}

@-webkit-keyframes load {
  0% {
    -ms-transform: rotate(7deg);
    /* IE 9 */
    -webkit-transform: rotate(7deg);
    /* Chrome, Safari, Opera */
    transform: rotate(7deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    /* IE 9 */
    -webkit-transform: rotate(360deg);
    /* Chrome, Safari, Opera */
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    -ms-transform: rotate(7deg);
    /* IE 9 */
    -webkit-transform: rotate(7deg);
    /* Chrome, Safari, Opera */
    transform: rotate(7deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    /* IE 9 */
    -webkit-transform: rotate(360deg);
    /* Chrome, Safari, Opera */
    transform: rotate(360deg);
  }
}

.custom-value-process {
  border: 0 solid red !important;
  color: white !important;
}

.custom-value-prev .page-link.disabled {
  background-color: #D2B14A;
  color: black !important;
  border-color: #D2B14A;
}

.custom-value-process .page-link.disabled a {
  color: black !important;
}

.custom-value-process .page-link a {
  color: black;
}

.custom-value-process .page-link.active {
  background-color: #BC9018;
  border-color: #BC9018;
  color: black !important;
}

.custom-value-process .page-link:hover {
  color: black;
}

.submitSpan {
  color: #BC9018;
  font-size: 14px;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<custom-register-one>>>>>>>>>>>>>>>>>>>>>> */
.custom-register-one {
  border: 0px solid red;
  /* padding:20px; */
  background-image: url(../src/assets/image/Ellipse-22.png);
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 20%;
}

.custom-register-one-inside {
  border: 0px solid red;
  padding: 30px;
  background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
  border-radius: 2em;
  /* margin-top:50px; */
}

.custom-register-one-inside input {
  background-color: transparent;
  border: 1px solid #ffff;
  padding: 0.8em;
  margin-bottom: 2em;
  justify-content: center;
  box-shadow: none;
  outline: none;
  color: #ffff;
}

.custom-register-one-inside .form-control:focus {
  background-color: transparent;
  outline: none !important;
  color: #ffff !important;
  border: 1px solid #ffff !important;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>> */


.verify-email {
  text-align: center;
  color: #BC9018;
  margin-bottom: 15px;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-flag-select button .ReactFlagsSelect-module_selectValue__152eS {
  width: 90% !important;
}

label {
  pointer-events: none;
}

label {
  cursor: default;
}

.top-modal-1 {
  position: absolute;
  right: 16px;
}

.custom-contact-form .diconlogo {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.custom-contact-form .icon-fs {
  font-size: 10px;
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

@media(min-width:570px) and (max-width:991px) {
  .custom-token-box {
    margin-left: 35px;
  }
}

.text-error-postioning {
  position: absolute;
}

.wallet-loader {
  /* color: #bc9018 !important; */
  color: #bc9018 !important;
}

/* .coin-select-1 {
  cursor: pointer !important;
} */
.coin-select-1 {
  cursor: pointer !important;
}

.spinner-btn-1 {
  border: 1px solid var(--primary-color);
  padding: 8px 0px 0px 0px;
  text-transform: capitalize;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
  min-width: 120px;
  min-height: 50px;
  display: inline-block;
  margin-top: 10px;
}

.forgetpassword-btn {
  border: 1px solid var(--primary-color);
  padding: 8px 0px 0px 0px;
  text-transform: capitalize;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
  min-width: 200px;
  min-height: 50px;
  display: inline-block;
  margin-top: 10px;
}