@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

* {
  margin: 0px;
  padding: 0px;
}

/* :root {
  --primary-color: #ff5604;
  --secondary-color: white;
} */

body {
  font-family: "Poppins";
}
.customer-privacy{
    border:0px solid red;
    margin:0px -12px;
    padding:120px 40px;
}
.custom-inside-privacy{
    border:0px solid red;
}
.custom-inside-privacy span {
    color:var(--primary-color);
}
.custom-inside-privacy h4{
    color:var(--primary-color);
}
.custom-inside-privacy h3{
    color:var(--primary-color);
    text-transform: uppercase;
}
@media(max-width:991px){
    .customer-privacy{
        padding:100px 0px;
    }
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custome-terms{
    border:0px solid red;
    margin:0px -12px;
    padding:120px 30px;
}
.custome-terms-inside{
    border:0px solid red;
}
.custome-terms-inside h3{
    color: var(--primary-color);
    text-transform: uppercase;
}
.custome-terms-inside span{
    color: var(--primary-color);
}
@media(max-width:991px){
    .custome-terms{
        padding:100px 0px;
    }
}